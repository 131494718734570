import React from "react";
import { Editor, IAllProps } from "@tinymce/tinymce-react";
import ReactQuill, { ReactQuillProps } from "react-quill";

// interface IVuiEditor extends IAllProps {
//   onChange?: (val: any) => void;
// }

interface IVuiEditor extends ReactQuillProps {
  onChange?: (val: any) => void;
  apiKey?: string;
  disabled?: boolean;
  init?: unknown;
}

export const vuiEditorBaseInit: any = {
  branding: false,
  height: 200,
  placeholder: "",
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | formatselect | code " +
    "bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help ",
};

const VuiEditor: React.FC<IVuiEditor> = ({ onChange, ...props }) => {
  return (
    <ReactQuill
      {...props}
      theme="snow"
      readOnly={props.disabled}
      onChange={(value) => {
        onChange?.(value);
      }}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [
            "bold",
            "italic",
            "underline",
            "blockquote",
            "code-block",
            { color: [] },
          ],
          [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
      }}
    />
    // <Editor
    //   {...props}
    //   cloudChannel="5-stable"
    //   onEditorChange={(content: any) => onChange?.(content)}
    // />
  );
};

export default VuiEditor;
